<template>
  <div>
    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="$store.state.loading"
    ></BaseLoading>

    <div v-else class="text-left">
      <BaseHeader :title="'My Bids'"></BaseHeader>

      <div class="card mt-2 border-0 bg-white shadow">
        <!-- table headers  -->
        <div class="card-body text-right d-flex" style>
          <v-text-field
            v-model="$store.state.search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="my-auto"
          ></v-text-field>
          <!-- <v-spacer></v-spacer> -->
        </div>
        <!-- admin editor bids table -->
        <v-data-table
          :mobile-breakpoint="0"
          :headers="bidsTableAdmin"
          :items="bids"
          :search="$store.state.search"
          v-if="userType != userTypes.writer"
          dense
        >
          <template v-slot:[`item.order_id`]="{ item }">
            <router-link
              :to="{ name: 'Order', params: { id: item.order_id } }"
              >{{ item.order_id || "" }}</router-link
            >
          </template>
          <template v-slot:[`item.bids`]="{ item }">
            <table class="table sub-table">
              <!-- <thead>
                <tr>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Writer Level</th>
                  <th>Writer Amount</th>
                </tr>
              </thead> -->
              <tbody>
                <tr v-for="(bid, index) in item.bids" :key="index">
                  <td>{{ bid.create_stamp || "" }}</td>
                  <td>
                    <router-link
                      :to="{
                        name: 'WriterProfile',
                        params: { id: bid.writer_id || '' },
                      }"
                      >{{ bid.writer_name }}</router-link
                    >
                  </td>
                  <td>{{ bid.current_orders || 0 }} Current Orders</td>
                  <td>{{ bid.writer_level || "" }}</td>
                  <td>
                    {{ bid.writer_amount ? `$${bid.writer_amount}` : "" }}
                  </td>
                  <td>
                    <div class="d-flex align-center">
                      <v-btn
                        @click="
                          $store.state.dialog = true;
                          activeBid = bid;
                          action = 'assign';
                        "
                        color="primary"
                        class="primary--text"
                        small
                        outlined
                        >Assign</v-btn
                      >
                      <v-btn
                        elevation="0"
                        class="white--text ml-1"
                        fab
                        x-small
                        @click="
                          $store.state.dialog = true;
                          activeBid = bid;
                          action = 'delete';
                        "
                      >
                        <v-icon small class="error--text">mdi-delete</v-icon>
                      </v-btn>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
          <!-- <template v-slot:[`item.action`]="{ item }">
            <v-btn-toggle>
              <v-btn
                @click="
                  $store.state.dialog = true;
                  activeBid = item;
                  action = 'assign';
                "
                color="success"
                small
                >Assign</v-btn
              >
            </v-btn-toggle>
          </template> -->
        </v-data-table>
        <!-- writer bids table -->
        <v-data-table
          v-else
          :mobile-breakpoint="0"
          :headers="bidsTableHeaders"
          :items="bids"
          :search="$store.state.search"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-btn-toggle>
              <v-btn
                color="error"
                @click="
                  $store.state.dialog = true;
                  activeBid = item;
                  action = 'cancel';
                "
                small
                >Cancel</v-btn
              >
            </v-btn-toggle>
          </template>
        </v-data-table>
      </div>
    </div>
    <!-- bid -->
    <v-dialog
      v-model="$store.state.dialog"
      max-width="290"
      class="text-left"
      transition="dialog-top-transition"
    >
      <v-card :loading="bidding">
        <v-card-title class="text-h5 text-capitalize">
          Bid (#{{ activeBid.id || "" }})
        </v-card-title>

        <v-card-text class="text-left">
          <h4 class="text-capitalize">
            {{ action }} Bid
            {{
              action === "assign"
                ? `to ${activeBid.writer_name} id #${activeBid.writer_id}`
                : ""
            }}
          </h4>
          Are you sure you want perform this action?
        </v-card-text>

        <v-card-actions>
          <v-btn text @click="$store.state.dialog = false"> Close </v-btn>

          <v-btn
            :color="action == 'assign' ? 'green darken-1' : 'error darken-1'"
            text
            @click="bidAction"
            :disabled="bidding"
          >
            Yes, {{ action }} Bid
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- bid -->
  </div>
</template>

<script>
import http from "../../modules/http.index";
import { mapActions, mapState, mapGetters } from "vuex";
export default {
  name: "MyBids",
  computed: {
    ...mapState("orders", [
      "bids",
      "filters",
      "bidsTableHeaders",
      "bidsTableAdmin",
    ]),
    ...mapGetters("auth", ["userType", "userTypes"]),
  },
  data: () => ({
    action: "",
    bidding: false,
    activeBid: {},
  }),
  mounted() {
    this.$store.state.loading = true;
    this.getBids();
    this.$store.state.loading = false;
  },
  methods: {
    ...mapActions("orders", ["getBids"]),
    ...mapActions("sidebar", ["getSidebarMenu"]),
    bidAction() {
      let data = {
        bid_id: this.activeBid.id,
      };

      this.bidding = true;
      this.action === "assign"
        ? http
            .post(`/Bids/AcceptBid`, data)
            .then((res) => {
              this.getBids();
              this.getSidebarMenu();
              this.$notify({
                title: "Success",
                text:
                  (res.data && res.data.Message) ||
                  "Bid assigned successifully.",
                style: "success",
              });
            })
            .catch((err) => {
              console.log("assign bid error", err);
              this.$notify({
                title: "Error",
                text:
                  (err.response && err.response.data.Message) ||
                  "Error while assigning bid",
                style: "danger",
              });
            })
            .finally(() => (this.bidding = false))
        : http
            .delete(`/Bids/Bid/${data.bid_id}`)
            .then((res) => {
              this.getBids();
              this.getSidebarMenu();
              this.$notify({
                title: "Success",
                text:
                  (res.data && res.data.Message) ||
                  "Bid deleted successifully.",
                style: "success",
              });
            })
            .catch((err) => {
              console.log("delete bid error", err);
              this.$notify({
                title: "Error",
                text:
                  (err.response && err.response.data.Message) ||
                  "Error while deleting bid",
                style: "danger",
              });
            })
            .finally(() => (this.bidding = false));

      this.$store.state.dialog = false;
    },
  },
};
</script>
<style lang="scss" scoped>

td {
  min-width: 100px !important;
}
</style>